import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import Icon from 'Components/Icon';
import Text from 'Components/Text';

import logo from 'Assets/logo_normal.svg';
// import logo from 'Assets/logo-blanco-navidad.svg';
import { routes } from 'Constants';

import { useUser } from '../../../users/utils';
import { isCorpo } from '../../../../Services/Users/types';

const MobileNavbar = ({
  handleSelect,
  settings,
  engines,
  activeEngine,
  smallMode,
  hideHeader,
  onLogoClick,
  hidden
}) => {
  const user = useUser();

  return (
    <>
      <Container hideHeader={hideHeader || smallMode} hidden={hidden}>
        <Header>
          <Nav>
            <Link to={routes.home} onClick={onLogoClick}>
              <Logo alt="me-vuelo" src={logo} />
            </Link>
          </Nav>
        </Header>
        <NavBarSlider {...settings}>
          {Object.values(engines).map(engine => {
            const active = engine.name === activeEngine;

            // TODO: THIS IS A TEMPORAL FIX
            if (!user && engine.isPrivate) {
              return;
            }

            if (isCorpo(user) && !engine.showInCorpo) {
              return;
            }

            return (
              // Wrapper is needed because of slick styles bleeding down
              <div key={engine.name}>
                <NavItem
                  onClick={handleSelect(engine.name, engine.path)}
                  to={engine.path}>
                  <NavBarIcon>
                    <Icon
                      name={engine.icon}
                      size={24}
                      color={active ? 'important' : 'default'}
                    />
                  </NavBarIcon>
                  {!smallMode && (
                    <NavBarLabel context={active ? 'important' : 'default'}>
                      {engine.name}
                    </NavBarLabel>
                  )}
                </NavItem>
              </div>
            );
          })}
        </NavBarSlider>
      </Container>
      <Container forSpace hideHeader={hideHeader || smallMode} hidden={hidden}>
        {/* Using this so tht we dont have two <header> tags on the final dom */}
        <Header as="div" />
        <NavBarSlider {...settings}>
          <NavItem to="">
            <NavBarIcon>
              <Icon size={24} />
            </NavBarIcon>
            {!smallMode && <NavBarLabel>space</NavBarLabel>}
          </NavItem>
        </NavBarSlider>
      </Container>
    </>
  );
};

MobileNavbar.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  settings: PropTypes.object,
  engines: PropTypes.object,
  activeEngine: PropTypes.string.isRequired,
  smallMode: PropTypes.bool,
  hideHeader: PropTypes.bool,
  onLogoClick: PropTypes.func.isRequired,
  hidden: PropTypes.bool
};

export default MobileNavbar;

const Container = styled.div`
  width: 100%;
  transition: all 0.4s ease-in-out;
  z-index: ${({ theme }) => theme.zIndex.topNavbar};
  position: fixed;
  top: 0;
  ${({ hidden }) =>
    hidden &&
    `
    opacity: 0;
    height: 0px;
  `};

  /* Changed translateY because translate does not alter the layouts */
  ${({ hideHeader }) => hideHeader && 'margin-top: -75px;'}
  ${({ forSpace }) =>
    forSpace &&
    `
  position: relative;
  visibility: hidden;
  `}
`;

const Header = styled.header`
  height: 100%;
  height: 70px;
  background-color: ${({ theme }) => theme.colors.newBackground};
  border-bottom: 2px solid ${({ theme }) => theme.colors.important};
`;

const Nav = styled.nav`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  position: relative;
  width: 144px;
  margin-left: ${({ theme }) => theme.spacing.four};
`;

const NavBarSlider = styled(Slider)`
  display: flex;
  transition: 0.2s;
  list-style-type: none;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => `
    padding: ${theme.spacing.four} 0 ${theme.spacing.three};
    box-shadow: inset 0 -1px 3px ${theme.colors.navBarGrey};
  `}
`;

const NavItem = styled(Link)`
  padding: 0 ${({ theme }) => theme.spacing.four};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const NavBarIcon = styled.div`
  background-color: ${({ theme }) => theme.gradients.greyBkg};
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.shadows.two};
  height: 48px;
  width: 48px;
  margin-bottom: ${({ theme }) => theme.spacing.one};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavBarLabel = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  text-align: center;
`;
